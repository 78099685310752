import ITranslation from "./translation";

const translationsEN: ITranslation = {
	actions: "Actions",
	action: "Action",
	active: "Active",
	Add: "Add",
	add: "Add",
	add_at_least_one: "Please add at lease 1 {{entity}}.",
	address: "Address",
	already_exists: "{{entity}} allready exists",
	amount: "Amount",
	Approved: "Approved",
	april: "April",
	archive: "Archive",
	ask_save: "There are still unsaved changes. Do you wish to save them?",
	asDraft: "as draft",
	assignee: "Assignee",
	Assignment: "Assignment",
	assignment: "Assignment",
	assignmentDescription: "Assignment description",
	assignmentDescriptions: "Assignment Descriptions",
	assignmentNumber: "Opdracht number",
	AssignmentRemark: "Assignment Remark",
	assignments: "Assignments",
	assignmentType: "Assignmenttype",
	assignmentTypes: "Assignmenttypes",
	attachment: "Attachment",
	attachments: "Attachments",
	Attended: "Attended",
	august: "August",
	AwaitingReminder: "Awaiting reminder",
	body: "Body",
	calendar: {
		today: "TODAY"
	},
	cancel: "Cancel",
	Cancelled: "Cancelled",
	cancelling: "cancelling",
	category: "Category",
	checklists: "Chccklists",
	chooseColleague: "Please choose a colleague.",
	close: "Close",
	colleague: "Colleague",
	colleagueTechnician: "Colleague technician",
	color: "Color",
	communication: "Communication",
	communicationTemplates: "Communicationtemplates",
	communicationTemplatesSplitted: "Communication-templates",
	complete: "Complete",
	Completed: "Completed",
	confirm_change_while_planned_interventions:
		"This user has interventions with status 'Planned'. If you wish to deactivate this user, please finish these interventions or assign them to another technician. Do you wish to continue?",
	confirm_choice_technicians: "1 or more technicians already have a planned intervention on the date you selected. Do you wish to continue with your choice?",
	confirm_content: "Are you sure you wish to {{action}} this record?",
	confirm_currentuser_activechange: "You are deactivating your own account. If you save this, you won't be able to log in to the application. Do you wish to continue?",
	confirm_currentuser_rolechange: "You are changing the roles of you own account. It is possible you cannot reverse this yourselve. Do you wish to continue?",
	confirm_different_technician: "The technician assigned to this intervention is different from the technician for whom you want to schedule this intervention. Do you wish to continue?",
	confirm_file_remove: "Are you sure you wish to remove this file?",
	confirm_offer_content: "Do you wish to add a new offer?",
	confirm_offer_title: "Confirm offer",
	confirm_reset: "Are you sure you wish to reset the settings?",
	confirm_role_update_current_user: "You are changeing a role attached to your account. It is possible you cannot reverse this yourselve. Do you wish to continue?",
	confirm_title: "Confirm {{action}}",
	confirmLeave: "You have unsaved data, are you sure you want to leave without saving your changes?",
	contact: "Contact",
	contacts: "Contacts",
	contactType: "Contacttype",
	contactTypes: "Contacttypes",
	copyToNewIntervention: "Copy to new intervention",
	costCenter: "Cost Center",
	count: "Count",
	country: "Country",
	createdDate: "Created On",
	createNew: "This <1 /> is not found.<3 />Do you wish to create a new one?",
	currentMonth: "Current month",
	currentWeek: "Current week",
	customer: "Customer",
	customerMaterial: "Customer - Material",
	customers: "Customers",
	customerService: "Customer - Service",
	date: "Date",
	dateinput: {
		decrement: "Decrease value",
		increment: "Increase value"
	},
	datepicker: {
		toggleCalendar: "Toggle calendar"
	},
	datetimepicker: {
		cancel: "Cancel",
		date: "Date",
		set: "Set",
		time: "Time",
		toggleDateTimeSelector: "Toggle date-time selector"
	},
	day: "day",
	deactivatingPlannedUser: "You are deactivating a user with planned interventions.",
	deadline: "Deadline",
	december: "December",
	Declined: "Declined",
	defaultRate: "Default rate €",
	Delete: "Delete",
	description: "Description",
	details: "Details",
	Dispatched: "Dispatched",
	downloadCostFile: "Download cost overview",
	Draft: "Draft",
	edit: "Edit",
	email: "Email",
	en: "English",
	entity: "Entity",
	error: "Error",
	Error: "Error",
	errors: {
		error_argumentnull: "An {{argument}} was empty in the serverside",
		error_connection: "Unable to make an external connection",
		error_constraintfailure: "A database contstraint prevented this action",
		error_dbconcurrency: "This record is changed or deleted by someone else. Please refresh.",
		error_date: "Please, choose a date.",
		error_duplicate: "This entity allready exists",
		error_entityused: "This entity is still used in the application. If you don't want to see it anymore, please deactivate it",
		error_general: "An error on the servers side has occured",
		error_idfilledin: "The ID cannot be filled in while adding an entity",
		error_idnotfilledin: "The ID must be filled in when deleteing",
		error_nofile: "No file provided",
		error_routeiddoesnotmatch: "The ID in the route does not match the ID in the entity",
		error_securitydocumencopyfailed: "Copy for security document '{{file}}' because of reason: '{{reason}}'",
		error_server_validation: "Server validation error",
		error_sqlconnection: "Unable to make a database connection",
		error_sqlgeneral: "A database error occured",
		error_validation: "The server side validation failed",
		forbidden: "You don't have access to this part of the server application",
		not_authenticated: "You are not logged in at the server",
		not_found: "The server did not find a result",
		only_one_approved_offer_allowed: "You can only have one 'Approved' offer",
		server_error: "The server returned a '{{statuscode}}' response."
	},
	establishment: "Establishment",
	EstimatedTimeOnSite: "Estimated time on site",
	example: "Example",
	extra: "Extra",
	february: "February",
	file: "File",
	filename: "Filename",
	fill_in_correct_format: "Please fill in a correct value for the field '{{field}}'. Ex: '{{example}}'.",
	fill_in_correct_location_hours: "Please fill in valid hours for the location hours.",
	fill_in_email_contact: "Please fill in an email at 'contact'.",
	fill_in_minimum_value: "Please fill in a minimum of {{minimum}} in field '{{field}}'.",
	fill_in_required_field: "Please fill in the required field '{{field}}'.",
	fill_in_technician_when_planned: "Please fill in a technician when you wish to save an intervention as 'Planned'.",
	Finished: "Finished",
	finished: "Finished",
	firstName: "First name",
	fixedDate: "Fixed date",
	friday: "Friday",
	fri_tr: "Fri-tr",
	fri_w: "Fri-w",
	fullDayAmount: "Full day amount",
	fullDayOnSite: "Full day on site",
	fullName: "Full name",
	fullOffer: "Full offer",
	gamma: "Gamma",
	general: "General",
	Green: "Green",
	grid: {
		filterAfterOperator: "Is after",
		filterAfterOrEqualOperator: "Is after or equal to",
		filterAndLogic: "And",
		filterBeforeOperator: "Is before",
		filterBeforeOrEqualOperator: "Is before or equal to",
		filterBooleanAll: "(All)",
		filterClearButton: "Clear",
		filterContainsOperator: "Contains",
		filterEndsWithOperator: "Ends with",
		filterEqOperator: "Is equal to",
		filterGteOperator: "Is greater than or equal to",
		filterGtOperator: "Is greater than",
		filterIsEmptyOperator: "Is empty",
		filterIsFalse: "No",
		filterIsNotEmptyOperator: "Is not empty",
		filterIsNotNullOperator: "Is not null",
		filterIsNullOperator: "Is null",
		filterIsTrue: "Yes",
		filterLteOperator: "Is less than or equal to",
		filterLtOperator: "Is less than",
		filterNotContainsOperator: "Does not contain",
		filterNotEqOperator: "Is not equal to",
		filterOrLogic: "Or",
		filterStartsWithOperator: "Starts with",
		filterSubmitButton: "Filter",
		filterTitle: "Filter",
		groupPanelEmpty: "Drag a column header and drop it here to group by that column",
		noRecords: "No records available",
		pagerFirstPage: "Go to the first page",
		pagerInfo: "{0} - {1} of {2} items",
		pagerItemsPerPage: "items per page",
		pagerLastPage: "Go to the last page",
		pagerNextPage: "Go to the next page",
		pagerOf: "of",
		pagerPage: "Page",
		pagerPreviousPage: "Go to the previous page",
		sortAscending: "Sort Ascending",
		sortDescending: "Sort Descending"
	},
	import: "Import",
	importFromCategory: "Import from category",
	importMethod: "Import method",
	help: "Help",
	home: "Home",
	hoursOnLocation: "Hours on location",
	InExecution: "In execution",
	Intervention: "Intervention",
	intervention: "Interventie",
	InterventionMaterial: "Material",
	InterventionRemark: "Intervention remark",
	interventions: "Interventies",
	interventionsToBePlanned: "Interventions - To be planned",
	InTheMaking: "In the making",
	InTreatment: "In Treatment",
	Invoiced: "Invoiced",
	invoiceDate: "Invoide Date",
	invoiceNumber: "Invoice Number",
	invoicePerHour: "Invoiced per hour",
	invoiceRemark: "Invoice Remark",
	invoicing: "Invoicing",
	InvoicingApprovalRequired: "Invoicing approval required",
	invoicingApprovalRequired: "Invoicing approval required",
	itemsSelected: "items",
	january: "January",
	july: "July",
	june: "June",
	language: "Language",
	lastName: "Last name",
	lastPlannedIntervention: "Last planned intervention",
	location: "Location",
	locationNumber: "Locationnumber",
	locations: "Locations",
	locationStart: "Location start",
	locationStop: "Location stop",
	logout: "Log out",
	manageMaterials: "Manage materials",
	march: "March",
	margin: "Margin",
	masterdata: "Masterdata",
	material: "Material",
	materialCategories: "Material Categories",
	materialCategory: "Material Category",
	materialCost: "Costs material",
	materialPurchasePrice: "Material purchase price",
	materials: "Materials",
	materialSalesPrice: "Material sales price",
	may: "May",
	mobileApp: "Mobile app",
	mo_tr: "Mo-tr",
	mo_w: "Mo-w",
	monday: "Monday",
	month: "month",
	monthView: "Month view",
	multiviewcalendar: {
		nextView: "Navigate to next view",
		prevView: "Navigate to previous view"
	},
	municipality: "Municipality",
	myProfile: "My profile",
	myTasks: "My tasks",
	name: "Name",
	New: "New",
	newStatus: "New status",
	next: "Next",
	nextMonth: "Next month",
	nextWeek: "Next week",
	nl: "Dutch",
	no: "No",
	noMonday: "The date should me a Monday.",
	none: "None",
	NotAvailable: "Not Available",
	NotFinished: "Not finished",
	notFound: "{{entity}} not found",
	notificationSuccefullRequestd: "Notification sucessfully reqested",
	november: "November",
	number: "Number",
	numberOfTechnician: "Number of technician",
	october: "October",
	off: "Off",
	Offer: "Offer",
	offer: "Offer",
	offers: "Offers",
	ok: "Ok",
	on: "On",
	only_one_approved_offer_allowed: "You can only have one 'Approved' offer",
	only_one_invoice_per_hour_service_allowed: "You can only have one 'Invoice per Hour' service.",
	openAssignment: "Open assignment",
	or: "or",
	Orange: "Orange",
	Ordered: "Besteld",
	otherSmallMaterial: "Other small material",
	PartsOnOrder: 
	"Parts on order",
	pending_changes: "Changes",
	permission: "Permission",
	permissions: {
		ArchiveAccess: "Access",
		AssignmentDescriptionsAdd: "Add",
		AssignmentDescriptionsDelete: "Delete",
		AssignmentDescriptionsRead: "Read",
		AssignmentDescriptionsUpdate: "Update",
		AssignmentsAdd: "Add",
		AssignmentsClose: "Close",
		AssignmentsDelete: "Delete",
		AssignmentsInvoice: "Invoice",
		AssignmentsRead: "Read",
		AssignmentsUpdate: "Update",
		AssignmentTypesAdd: "Add",
		AssignmentTypesDelete: "Delete",
		AssignmentTypesRead: "Read",
		AssignmentTypesUpdate: "Update",
		CommunicationTemplatesAdd: "Add",
		CommunicationTemplatesDelete: "Delete",
		CommunicationTemplatesRead: "Read",
		CommunicationTemplatesUpdate: "Update",
		ContactsAdd: "Contacts Add",
		ContactsDelete: "Contacts Delete",
		ContactsRead: "Contacts Read",
		ContactsUpdate: "Contacts Update",
		ContactTypesAdd: "Add",
		ContactTypesDelete: "Delete",
		ContactTypesRead: "Read",
		ContactTypesUpdate: "Update",
		CustomerContactsAdd: "Contacts add",
		CustomerContactsDelete: "Contacts delete",
		CustomerContactsRead: "Contacts read",
		CustomerContactsUpdate: "Contacts update",
		CustomerLocationAdd: "Location add",
		CustomerLocationDelete: "Location delete",
		CustomerLocationRead: "Location read",
		CustomerLocationUpdate: "Location update",
		CustomerMaterialsAdd: "Materials add",
		CustomerMaterialsDelete: "Materials delete",
		CustomerMaterialsRead: "Materials read",
		CustomerMaterialsUpdate: "Materials update",
		CustomersAdd: "Add",
		CustomersDelete: "Delete",
		CustomerServicesAdd: "Services add",
		CustomerServicesDelete: "Services delete",
		CustomerServicesRead: "Services read",
		CustomerServicesUpdate: "Services update",
		CustomersRead: "Read",
		CustomersUpdate: "Update",
		EmailRequestsRead: "Read",
		InterventionsAdd: "Add",
		InterventionsCheckMaterial: "Check Material",
		InterventionsDelete: "Delete",
		InterventionsExecute: "Execute",
		InterventionsPlan: "Plan",
		InterventionsRead: "Read",
		InterventionsUpdate: "Update",
		MaterialCategoriesAdd: "Add",
		MaterialCategoriesDelete: "Delete",
		MaterialCategoriesRead: "Read",
		MaterialCategoriesUpdate: "Update",
		MaterialsAdd: "Add",
		MaterialsDelete: "Delete",
		MaterialsRead: "Read",
		MaterialsUpdate: "Update",
		MobileAppAccess: "Access",
		ConststructionYardChecks: "Construction Yard Checks",
		OffersAdd: "Add",
		OffersDelete: "Delete",
		OffersRead: "Read",
		OffersUpdate: "Update",
		RateTypesAdd: "Add",
		RateTypesDelete: "Delete",
		RateTypesRead: "Read",
		RateTypesUpdate: "Update",
		RolesAdd: "Add",
		RolesDelete: "Delete",
		RolesRead: "Read",
		RolesUpdate: "Update",
		ServicesAdd: "Add",
		ServicesDelete: "Delete",
		ServicesRead: "Read",
		ServicesUpdate: "Update",
		SettingsRead: "Read",
		SettingsUpdate: "Update",
		UsersAdd: "Add",
		UsersDelete: "Delete",
		UsersRead: "Read",
		UsersUpdate: "Update"
	},
	phase: "Phase",
	phone: "Phone",
	photos: "Photos",
	Planned: "Planed",
	planning: "Planning",
	poNumber: "PO Number",
	postalCode: "Postal code",
	previous: "Previous",
	prevMonth: "Previous month",
	prevWeek: "Previous week",
	Present: "Present",
	price: "Price €",
	priceCalculation: "Price calculation",
	priority: "Priority",
	Priority1: "Priority 1 (4 hours)",
	Priority2: "Priority 2 (8 hours)",
	Priority3: "Priority 3 (24 hours)",
	Priority4: "Priority 4 (48 hours)",
	Priority5: "Priority 5 (5 days)",
	Priority6: "Priority 6 (10 days)",
	Priority7: "Priority 7 (30 days",
	Priority8: "Priority 8 (60 days)",
	privacy: "Privacy",
	projectCode: "Projectcode",
	projectManager: "Projectmanager",
	province: "Province",
	purchasePrice: "€ Purchase price",
	Q8ErrorMessages: "These are the error messages that has been sent by the Q8 API: ",
	QuotationApproved: "Quotation Approved",
	QuotationDeclined: "Quotation Declined",
	Quoted: "Quoted",
	QuoteRequired: "Quote Required",
	rate: "Rate €",
	rateType: "Rate type",
	rateTypes: "Rate types",
	receivedDate: "Received Date",
	Red: "Red",
	Rejected: "Rejected",
	remark: "Remark",
	remarks: "Remarks",
	reminderBody: "Reminder",
	reminderDate: "Send reminder on",
	ReminderError: "Reminder error",
	remove: "Remove",
	requests: "Requests",
	requestWorkOrder: "Request workorder",
	required: "Required",
	reset: "Reset",
	Revisit: "Revisit",
	role: "Role",
	roles: "Roles",
	salesPrice: "Sales price",
	saturday: "Saturday",
	sat_tr: "Sat-tr",
	sat_w: "Sat-w",
	save: "Save",
	schedule: "Schedule",
	scheduledOn: "Scheduled on",
	secondPhone: "Second phone",
	security: "Security",
	securityDocument: "Security document",
	securityDocuments: "Security documents",
	select_at_least_one: "Please select at least one {{entity}}.",
	selectAll: "Select All",
	selectFilter: "select filter",
	send: "Send",
	Sending: "Sending",
	SendingReminder: "Sending reminder",
	sendPlannedEmail: "Verzend planning notification",
	Sent: "Sent",
	september: "September",
	service: "Service",
	services: "Services",
	settings: "Settings",
	showInMobileApp: "Show in mobile app",
	showMaterialPrices: "Show material prices",
	showMaterialPricesPdf: "Show material prices in workorder.pdf",
	showToBeSignedOff: "Show to be signed off assignments",
	SignedOff: "Signed Off",
	signedOff: "Signed off",
	signedOffBy: "Signed off by",
	signedOffDate: "Signed off date",
	signOff: "Sign off assignment",
	SolvedByPhone: "Solved by phone",
	status: "Status",
	Stopped: "Stopped",
	street: "Street",
	subject: "Subject",
	success: "Success",
	sunday: "Sunday",
	sun_tr: "Sun-tr",
	sun_w: "Sun-w",
	technician: "Technician",
	template: "Template",
	theseAreTheServerErrorMessages: "These are the server validation errors:",
	timeline: "Timeline",
	thursday: "Thursday",
	thu_tr: "Thu-tr",
	thu_w: "Thu-w",
	timepicker: {
		cancel: "Cancel",
		now: "NOW",
		selectNow: "Select Now",
		set: "Set",
		toggleClock: "Toggle Clock",
		toggleTimeSelector: "Toggle TimeSelector"
	},
	timeMaterial: "TimeMaterial",
	title: "Title",
	to: "To",
	ToBeOrdered: "To be ordered",
	ToBePrepared: "To be prepared",
	ToBeSent: "To be sent",
	total: "Total",
	tot_tr: "Tot-tr",
	tot_w: "Tot-w",
	totalMaterialPurchasePrice: "Total material purchase price",
	totalMaterialSalesPrice: "Totaa material sales price",
	totalPrice: "Total price",
	totalPurchasePrice: "Total purchase price",
	totalSalesPrice: "Total sales price",
	transportDuration: "Transport duration",
	tuesday: "Tuesday",
	tue_tr: "Tue-tr",
	tue_w: "Tue-w",
	type: "Type",
	Update: "Update",
	updatedDateByUser: "Last updated on",
	upload: {
		cancel: "Cancel",
		clearSelectedFiles: "Clear",
		dropFilesHere: "Drop files here to upload",
		files: "files",
		headerStatusUploaded: "Done",
		headerStatusUploading: "Uploading...",
		invalidFileExtension: "File type not allowed.",
		invalidFiles: "Invalid file(s). Please check file upload requirements.",
		invalidMaxFileSize: "File size too large.",
		invalidMinFileSize: "File size too small.",
		remove: "Remove",
		retry: "Retry",
		select: "Select files...",
		total: "Total",
		uploadSelectedFiles: "Upload"
	},
	urgent: "Urgent",
	urgentIntervention: "Urgent intervention",
	urgentInterventionAmount: "Urgent intervention amount",
	user: "User",
	users: "Users",
	VatBusiness21: "Businesses - 21%",
	VatEqualizationCustomers21: "Equalization customers - 21%",
	VatExemption0: "Exempt - 0%",
	VatIndividuals21: "Individuals, schools, ... - 21%",
	vatNumber: "VAT Number",
	vatRegime: "VAT Regime",
	vehicleType: "Vehicle type",
	view: "View",
	version: "Version",
	warehouse: "Warehouse",
	wednesday: "Wednesday",
	wed_tr: "Wed-tr",
	wed_w: "Wed-w",
	welcome_message: "Welcome ",
	woNumber: "WO Number",
	work: "work",
	WorkComplete: "Work complete",
	workOrders: "Work orders",
	workorderSuccesfullyRequested: "Workorder succesfully requested",
	workPermit: "Workpermit",
	year: "year",
	yes: "Yes",
	yourprofile: "Your profile"
};

export default translationsEN;
