import { AccountInfo } from "@azure/msal-browser";
import { IDictionary } from "@selas/models";
import { getInitialState, IEntityState } from "@selas/state-management";
import { AxiosError } from "axios";
import { Dictionary } from "lodash";

import { IAssignment, ICustomerMaterial, IIntervention, ISetting, ITaskItem, IUser } from "../utils/types/models";

export interface IAuthenticationState {
	authenticated: boolean;
	// tslint:disable-next-line: no-any
	loginError: any;
	account: AccountInfo;

	loadingUser: boolean;
	currentUser: IUser;
	savingCurrentUser: boolean;
	savingCurrentUserError: AxiosError;
}

export interface IPermssionState {
	permissions: {
		[key: string]: {
			[key: string]: string[];
		};
	};
	loading: boolean;
}

export const initialPermissionState: IPermssionState = {
	permissions: {},
	loading: false
};

export interface ISettingState {
	settingsLoading: boolean;
	settings: IDictionary<ISetting>;
	settingsLoadError: AxiosError;

	isUpdating: boolean;
	updateError: AxiosError;
}

export const initialSettingState: ISettingState = {
	settingsLoading: false,
	settings: {},
	settingsLoadError: null,

	isUpdating: false,
	updateError: null
};

export interface IAssignmentState extends IEntityState<IAssignment> {
	isDownloading: boolean;
	costFile: {
		filename: string;
		content: string | ArrayBuffer | ArrayBufferView | Blob;
	};
}

export const initialAssignmentState: IAssignmentState = {
	...getInitialState<IAssignment>(),
	isDownloading: false,
	costFile: null
};

export interface IInterventionState extends IEntityState<IIntervention> {
	isRequestingWorkOrder: boolean;
	workOrderRequestComplete: boolean;
	workOrderRequestError: AxiosError;
}

export const initialInterventionState: IInterventionState = {
	...getInitialState<IIntervention>(),
	isRequestingWorkOrder: false,
	workOrderRequestComplete: false,
	workOrderRequestError: null
};

export interface ICustomerMaterialState extends IEntityState<ICustomerMaterial> {
	isCopying: boolean;
	copyingComplete: boolean;
	copyingError: AxiosError;
}

export const initialCustomerMaterialState: ICustomerMaterialState = {
	...getInitialState<ICustomerMaterial>(),
	isCopying: false,
	copyingComplete: false,
	copyingError: null
};

export interface ITaskState {
	isLoading: boolean;
	entities: ITaskItem[];
	loadError: AxiosError;
}

export const initialTaskState: ITaskState = {
	isLoading: false,
	entities: [],
	loadError: null
};

export interface IPlannedInterventionState {
	isLoading: boolean;
	entities: IIntervention[];
	loadError: AxiosError;
	isUpdating: boolean;
	updateError: AxiosError;
}

export const initialPlannedInterventionState: IPlannedInterventionState = {
	isLoading: false,
	isUpdating: false,
	entities: [],
	loadError: null,
	updateError: null
};

export interface IAlreadyPlannedInterventionState {
	isLoading: boolean;
	data: Dictionary<number>;
	loadError: AxiosError;
}

export const initialAlreadyPlannedInterventionState: IAlreadyPlannedInterventionState = {
	isLoading: false,
	data: null,
	loadError: null
};

export interface IAssignmentNumberState {
	isLoading: boolean;
	data: string;
	loadError: AxiosError;
}

export const initialAssignmentNumberState: IAssignmentNumberState = {
	isLoading: false,
	data: null,
	loadError: null
};
