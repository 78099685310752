import { IAction } from "@selas/models";
import { AxiosError, AxiosResponse } from "axios";
import { Reducer } from "react";
import { initialTaskState, ITaskState } from "..";
import Endpoint from "../../services/api/endpoint";

const taskReducer: Reducer<ITaskState, IAction> = (state: ITaskState = initialTaskState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_GET_" + Endpoint.Tasks:
			state = {
				...state,
				isLoading: true,
				loadError: null
			};
			break;
		case "REQUEST_SUCCESS_GET_" + Endpoint.Tasks: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (action.payload && payload.response && payload.response.data) {
				state = {
					...state,
					entities: payload.response.data
				};
			}
			break;
		}
		case "REQUEST_FAILURE_GET_" + Endpoint.Tasks: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				loadError: payload.error
			};
			break;
		}
		case "REQUEST_END_GET_" + Endpoint.Tasks:
			state = {
				...state,
				isLoading: false
			};
			break;
		case "CLEAR_GET_" + Endpoint.Tasks:
			state = {
				...state,
				entities: initialTaskState.entities,
				isLoading: initialTaskState.isLoading,
				loadError: initialTaskState.loadError
			};
			break;
		default:
			return state;
	}
	return state;
};

export default taskReducer;
