import { IAction } from "@selas/models";
import { createEntityReducer } from "@selas/state-management";
import { AxiosResponse } from "axios";

import { IAssignmentState } from "..";
import Endpoint from "../../services/api/endpoint";
import { IAssignment } from "../../utils/types/models";

function assignmentReducer(state: IAssignmentState, action: IAction): IAssignmentState {
	switch (action.type) {
		case "REQUEST_GET_" + Endpoint.AssignementDownloadCosts:
			state = {
				...state,
				isDownloading: true,
				costFile: null
			};
			break;
		case "REQUEST_SUCCESS_GET_" + Endpoint.AssignementDownloadCosts:
			{
				const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
				if (payload && payload.response && payload.response.data) {
					const headerLine: string = payload.response.headers["content-disposition"];
					const startFileNameIndex: number = headerLine.indexOf("=") + 1 ;
					const endFileNameIndex: number = headerLine.indexOf(";", startFileNameIndex);
					const filename: string = headerLine.substring(startFileNameIndex, endFileNameIndex);
					state = {
						...state,
						costFile: {
							filename,
							content: payload.response.data
						}
					};
				}
			}
			break;
		case "REQUEST_END_GET_" + Endpoint.AssignementDownloadCosts:
			state = {
				...state,
				isDownloading: false
			};
			break;
		case "CLEAR_GET_" + Endpoint.AssignementDownloadCosts:
			state = {
				...state,
				isDownloading: true,
				costFile: null
			};
			break;
	}
	return state;
}

export default createEntityReducer<IAssignment, IAssignmentState>(Endpoint.Assignments, Endpoint.AssignmentsList, assignmentReducer);
