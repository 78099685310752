import ITranslation from "./translation";

const translationsNL: ITranslation = {
	actions: "Acties",
	action: "Actie",
	active: "Actief",
	Add: "Toegevoegd",
	add: "Toevoegen",
	add_at_least_one: "Gelieve minstens 1 {{entity}} toe te voegen",
	address: "Adres",
	already_exists: "{{entity}} bestaat al",
	amount: "Aantal",
	Approved: "Goedgekeurd",
	april: "april",
	archive: "Archief",
	asDraft: "als concept",
	ask_save: "Er zijn nog steeds niet opgeslagen wijzigingen. Wilt u deze opslaan?",
	assignee: "Verantwoordelijke",
	Assignment: "Opdracht",
	assignment: "Opdracht",
	assignmentDescription: "Opdracht omschrijving",
	assignmentDescriptions: "Opdracht Omschrijvingen",
	assignmentNumber: "Opdrachtnummer",
	AssignmentRemark: "Opdracht opmerking",
	assignments: "Opdrachten",
	assignmentType: "Opdrachttype",
	assignmentTypes: "Opdrachttypes",
	attachment: "Bijlage",
	attachments: "Bijlagen",
	Attended: "Bijgewoond",
	august: "augustus",
	AwaitingReminder: "Reminder te verzenden",
	body: "Body",
	calendar: {
		today: "Vandaag"
	},
	cancel: "Annuleer",
	Cancelled: "Geannuleerd",
	cancelling: "annuleren",
	category: "Categorie",
	checklists: "Controlelijsten",
	chooseColleague: "Gelieve een collega te kiezen.",
	close: "Sluiten",
	colleague: "Collega",
	colleagueTechnician: "Collega technieker",
	color: "Kleur",
	communication: "Communicatie",
	communicationTemplates: "Communicatietemplates",
	communicationTemplatesSplitted: "Communicatie-templates",
	complete: "Volledig",
	confirm_change_while_planned_interventions:
		"Deze gebruiker heeft nog interventies met status 'Gepland'. Indien u deze wil deactiveren, gelieve deze interventies af te handelen of toe te wijzen aan een andere technieker. Wenst u door te gaan?",
	confirm_choice_technicians: "1 of meerdere techniekers hebben al een interventie gepland op de gekozen datum. Wilt u toch doorgaan met deze keuze?",
	confirm_content: "Bent u zeker dat u dit record wil {{action}}?",
	confirm_currentuser_activechange: "Je deactiveert je eigen account. Je zal niet meer kunnen inloggen als je opslaat. Wil je doorgaan?",
	confirm_currentuser_rolechange: "Je wijzigt de rollen van je eigen account. Mogelijks kan je dit niet meer zelf ongedaan maken. Wil je doorgaan?",
	confirm_different_technician: "De technieker aan deze interventie komt niet overeen met de technieker waarvoor je de interventie wilt plannen. Wil je doorgaan?",
	confirm_file_remove: "Bent u zeker dat u deze file wil verwijderen?",
	confirm_offer_content: "Wilt u een nieuwe offerte toevoegen?",
	confirm_offer_title: "Bevestiging offerte",
	confirm_reset: "Bent u zeker dat u de instellingen wil terugzetten?",
	confirm_role_update_current_user: "Je verandert de permissies van een rol die aan jouw account hangt. Mogelijks kan je dit niet meer zelf ongedaan maken. Wil je doorgaan?",
	confirm_title: "Bevesting {{action}}",
	confirmLeave: "De data is niet opgeslagen, ben je zeker dat je dit scherm wil verlaten?",
	contact: "Contact",
	contacts: "Contacten",
	contactType: "Contacttype",
	contactTypes: "Contacttypes",
	copyToNewIntervention: "Kopieer naar nieuwe interventie",
	costCenter: "Kostenpost",
	Completed: "Afgehandeld",
	count: "Aantal",
	country: "Land",
	createdDate: "Aangemaakt op",
	createNew: "Dit <1/> is niet gevonden.<3/>Wilt u een nieuw aanmaken?",
	currentMonth: "Deze maand",
	currentWeek: "Deze week",
	customer: "Klant",
	customerMaterial: "Klant - Materiaal",
	customers: "Klanten",
	customerService: "Klant - Dienst",
	date: "Datum",
	dateinput: {
		decrement: "Verlaag waarde",
		increment: "Verhoog waarde"
	},
	datepicker: {
		toggleCalendar: "Toggle calendar"
	},
	datetimepicker: {
		cancel: "Annuleer",
		date: "Datum",
		set: "Kies",
		time: "Tijd",
		toggleDateTimeSelector: "Open/sluit"
	},
	day: "dag",
	deactivatingPlannedUser: "Je deactiveert een gebruiker die nog ingeplande interventies heeft.",
	deadline: "Deadline",
	december: "december",
	Declined: "Geweigerd",
	defaultRate: "Standaard tarief €",
	Delete: "Verwijderd",
	description: "Beschrijving",
	details: "Details",
	Dispatched: "Verzonden",
	downloadCostFile: "Download kostenoverzicht",
	Draft: "Concept",
	edit: "Wijzigen",
	email: "Email",
	en: "Engels",
	entity: "Entiteit",
	error: "Error",
	Error: "Error",
	errors: {
		error_argumentnull: "Er was een leeg {{argument}} op de server.",
		error_connection: "Er kan geen verbinding met een externe service gemaakt worden.",
		error_constraintfailure: "Een database validatie regel is gefaald.",
		error_dbconcurrency: "Dit record is in tussentijd gewijzigd of verwijderd door iemand anders. Gelieve te refreshen.",
		error_date: "Gelieve een datum te kiezen.",
		error_duplicate: "Dit record bestaat al.",
		error_entityused: "Deze entiteit wordt nog steeds gebruikt binnen de applicatie. Als je deze niet wil zien in de schermen, gelieve deze dan te deactiveren.",
		error_general: "Er is een error op de server gebeurd.",
		error_idfilledin: "De ID kan niet ingevuld zijn als je een entiteit toevoegd.",
		error_idnotfilledin: "De ID moet meegegeven worden als je probeert te deleten.",
		error_nofile: "Geen file om te uploaden.",
		error_routeiddoesnotmatch: "De ID in de url-route komt niet overeen met de ID in de entiteit.",
		error_securitydocumencopyfailed: "Kopiëren van veiligheidsdocument '{{file}}' mislukt omwille van de volgende reden: '{{reason}}'",
		error_server_validation: "Server validatie error.",
		error_sqlconnection: "Er kan geen verbinding met de database gemaakt worden.",
		error_sqlgeneral: "Er is een database error gebeurd.",
		error_validation: "Er is een validatie error.",
		forbidden: "Je hebt geen rechten op dit deel van de server.",
		not_authenticated: "Je bent niet ingelogd op de server.",
		not_found: "De server heeft geen resultaat gevonden.",
		only_one_approved_offer_allowed: "Er kan maar 1 goedgekeurde offerte zijn",
		server_error: "De server heeft een '{{statuscode}}' antwoord terug gestuurd."
	},
	establishment: "Vestiging",
	EstimatedTimeOnSite: "Geschatte tijd ter plaatse",
	example: "Voorbeeld",
	extra: "Extra",
	february: "februari",
	file: "Bestand",
	filename: "Bestandsnaam",
	fill_in_correct_format: "Gelieve een correcte waarde in het veld '{{field}}' in te vullen. Bvb: '{{example}}'.",
	fill_in_correct_location_hours: "Gelieve correcte waardes in te vullen bij locatie-uren.",
	fill_in_email_contact: "Gelieve een email in te vullen bij 'contact'.",
	fill_in_minimum_value: "Gelieve een minimum van {{minimum}} in het veld '{{field}}' in te vullen.",
	fill_in_required_field: "Gelieve het verplicht veld '{{field}}' in te vullen.",
	fill_in_technician_when_planned: "Gelieve een technieker in te vullen wanneer u een interventie als 'Gepland' wilt opslaan.",
	Finished: "Afgewerkt",
	finished: "Afgewerkt",
	firstName: "Voornaam",
	fixedDate: "Vaste datum",
	friday: "Vrijdag",
	fri_tr: "Vr-tr",
	fri_w: "Vr-w",
	fullDayAmount: "Bedrag volledige dag",
	fullDayOnSite: "Volledige dag on-site",
	fullName: "Volledige naam",
	fullOffer: "Volledige offerte",
	gamma: "Gamma",
	general: "Algemeen",
	Green: "Groen",
	grid: {
		filterAfterOperator: "Is na",
		filterAfterOrEqualOperator: "Is na of gelijk aan",
		filterAndLogic: "En",
		filterBeforeOperator: "Is voor",
		filterBeforeOrEqualOperator: "Is voor of gelijk aan",
		filterBooleanAll: "(Alles)",
		filterClearButton: "Maak leeg",
		filterContainsOperator: "Bevat",
		filterEndsWithOperator: "Eindigt op",
		filterEqOperator: "Is gelijk aan",
		filterGteOperator: "Is groter dan of gelijk aan",
		filterGtOperator: "Is groter dat",
		filterIsEmptyOperator: "Is leeg",
		filterIsFalse: "Nee",
		filterIsNotEmptyOperator: "Is niet leeg",
		filterIsNotNullOperator: "Is niet null",
		filterIsNullOperator: "Is null",
		filterIsTrue: "Ja",
		filterLteOperator: "Is kleiner dan of gelijk aan",
		filterLtOperator: "Is kleiner dan",
		filterNotContainsOperator: "Bevat niet",
		filterNotEqOperator: "Is niet gelijk aan",
		filterOrLogic: "Of",
		filterStartsWithOperator: "Begint met",
		filterSubmitButton: "Filter",
		filterTitle: "Filter",
		groupPanelEmpty: "Sleep hier een kolom naar toe om erop te groeperen",
		noRecords: "Geen data beschikbaar",
		pagerFirstPage: "Ga naar de eerste pagina",
		pagerInfo: "{0} - {1} van {2} items",
		pagerItemsPerPage: "items per pagina",
		pagerLastPage: "Ga naar de laatste pagina",
		pagerNextPage: "Ga naar de volgende pagina",
		pagerOf: "van",
		pagerPage: "Pagina",
		pagerPreviousPage: "Ga naar de vorige pagina",
		sortAscending: "Sorteer Oplopend",
		sortDescending: "Sorteer Aflopend"
	},
	import: "Importeer",
	importFromCategory: "Importeer uit categorie",
	importMethod: "Importeer methode",
	help: "Help",
	home: "Startpagina",
	hoursOnLocation: "Uren op locatie",
	InExecution: "In uitvoering",
	Intervention: "Interventie",
	intervention: "Interventie",
	InterventionMaterial: "Materiaal",
	InterventionRemark: "Interventie opmerking",
	interventions: "Interventies",
	interventionsToBePlanned: "Interventies - Nog in te plannen",
	InTheMaking: "In de maak",
	InTreatment: "In Behandeling",
	Invoiced: "Gefactureerd",
	invoiceDate: "Factuur Datum",
	invoiceNumber: "Factuur Nummer",
	invoicePerHour: "Gefactureerd per uur",
	invoiceRemark: "Factuur Opmerking",
	invoicing: "Facturatie",
	InvoicingApprovalRequired: "Goedkeuring facturatie vereist",
	invoicingApprovalRequired: "Goedkeuring facturatie vereist",
	itemsSelected: "items",
	january: "januari",
	july: "juli",
	june: "juni",
	language: "Taal",
	lastName: "Achternaam",
	lastPlannedIntervention: "Laatste geplande interventie",
	location: "Locatie",
	locationNumber: "Locatienummer",
	locations: "Locaties",
	locationStart: "Locatie start",
	locationStop: "Locatie stop",
	logout: "Uitloggen",
	manageMaterials: "Beheer materiaal",
	march: "maart",
	margin: "Marge",
	masterdata: "Masterdata",
	material: "Materiaal",
	materialCategories: "Materiaal Categorieën",
	materialCategory: "Materiaal Categorie",
	materialCost: "Kosten materiaal",
	materialPurchasePrice: "Aankoopprijs materiaal",
	materials: "Materialen",
	materialSalesPrice: "Verkoopprijs materiaal",
	may: "mei",
	mobileApp: "Mobiele app",
	mo_tr: "Ma-tr",
	mo_w: "Ma-w",
	monday: "Maandag",
	month: "maand",
	monthView: "Maand overzicht",
	multiviewcalendar: {
		nextView: "Navigeer naar volgende view",
		prevView: "Navigeer naar vorige view"
	},
	municipality: "Gemeente",
	myProfile: "Mijn profiel",
	myTasks: "Mijn taken",
	name: "Naam",
	New: "Nieuw",
	newStatus: "Nieuwe status",
	next: "Volgende",
	nextMonth: "Volgende maand",
	nextWeek: "Volgende week",
	nl: "Nederlands",
	no: "Nee",
	noMonday: "De datum moet een maandag zijn.",
	none: "Geen",
	NotAvailable: "Niet leverbaar",
	NotFinished: "Niet afgewerkt",
	notFound: "{{entity}} niet gevonden",
	notificationSuccefullRequestd: "Notificatie succesvol aangevraagd",
	november: "november",
	number: "Nummer",
	numberOfTechnician: "Aantal techniekers",
	october: "oktober",
	off: "Uit",
	Offer: "Offerte",
	offer: "Offerte",
	offers: "Offertes",
	ok: "Ok",
	on: "Aan",
	only_one_approved_offer_allowed: "Er kan maar 1 goedgekeurde offerte zijn",
	only_one_invoice_per_hour_service_allowed: "Er kan maar 1 'Gefactureerd per uur' dienst zijn.",
	openAssignment: "Open opdracht",
	or: "of",
	Orange: "Oranje",
	Ordered: "Besteld",
	otherSmallMaterial: "Ander klein materiaal",
	PartsOnOrder: "Onderdelen op bestelling",
	pending_changes: "Wijzigingen",
	permission: "Permissie",
	permissions: {
		ArchiveAccess: "Toegang",
		AssignmentDescriptionsAdd: "Toevoegen",
		AssignmentDescriptionsDelete: "Verwijderen",
		AssignmentDescriptionsRead: "Lezen",
		AssignmentDescriptionsUpdate: "Wijzigen",
		AssignmentsAdd: "Toevoegen",
		AssignmentsClose: "Afmelden",
		AssignmentsDelete: "Verwijderen",
		AssignmentsInvoice: "Factureren",
		AssignmentsRead: "Lezen",
		AssignmentsUpdate: "Wijzigen",
		AssignmentTypesAdd: "Toevoegen",
		AssignmentTypesDelete: "Verwijderen",
		AssignmentTypesRead: "Lezen",
		AssignmentTypesUpdate: "Wijzigen",
		CommunicationTemplatesAdd: "Toevoegen",
		CommunicationTemplatesDelete: "Verwijderen",
		CommunicationTemplatesRead: "Lezen",
		CommunicationTemplatesUpdate: "Wijzigen",
		ContactsAdd: "Toevoegen",
		ContactsDelete: "Verwijderen",
		ContactsRead: "Lezen",
		ContactsUpdate: "Wijzigen",
		ContactTypesAdd: "Toevoegen",
		ContactTypesDelete: "Verwijderen",
		ContactTypesRead: "Lezen",
		ContactTypesUpdate: "Wijzigen",
		CustomerContactsAdd: "Contacten toevoegen",
		CustomerContactsDelete: "Contacten verwijderen",
		CustomerContactsRead: "Contacten lezen",
		CustomerContactsUpdate: "Contacten wijzigen",
		CustomerLocationAdd: "Locatie toevoegen",
		CustomerLocationDelete: "Locatie verwijderen",
		CustomerLocationRead: "Locatie lezen",
		CustomerLocationUpdate: "Locatie wijzigen",
		CustomerMaterialsAdd: "Materialen toevoegen",
		CustomerMaterialsDelete: "Materialen verwijderen",
		CustomerMaterialsRead: "Materialen lezen",
		CustomerMaterialsUpdate: "Materialen wijzigen",
		CustomersAdd: "Toevoegen",
		CustomersDelete: "Verwijderen",
		CustomerServicesAdd: "Diensten toevoegen",
		CustomerServicesDelete: "Diensten verwijderen",
		CustomerServicesRead: "Diensten lezen",
		CustomerServicesUpdate: "Diensten wijzigen",
		CustomersRead: "Lezen",
		CustomersUpdate: "Wijzigen",
		EmailRequestsRead: "Lezen",
		InterventionsAdd: "Toevoegen",
		InterventionsCheckMaterial: "Controleer Materiaal",
		InterventionsDelete: "Verwijder",
		InterventionsExecute: "Uitvoeren",
		InterventionsPlan: "Plannen",
		InterventionsRead: "Lezen",
		InterventionsUpdate: "Wijzigen",
		MaterialCategoriesAdd: "Toevoegen",
		MaterialCategoriesDelete: "Verwijderen",
		MaterialCategoriesRead: "Lezen",
		MaterialCategoriesUpdate: "Wijzigen",
		MaterialsAdd: "Toevoegen",
		MaterialsDelete: "Verwijderen",
		MaterialsRead: "Lezen",
		MaterialsUpdate: "Wijzigen",
		MobileAppAccess: "Toegang",
		ConststructionYardChecks: "Werfcontroles",
		OffersAdd: "Toevoegen",
		OffersDelete: "Verwijderen",
		OffersRead: "Lezen",
		OffersUpdate: "Wijzigen",
		RateTypesAdd: "Toevoegen",
		RateTypesDelete: "Verwijderen",
		RateTypesRead: "Lezen",
		RateTypesUpdate: "Wijzigen",
		RolesAdd: "Toevoegen",
		RolesDelete: "Verwijderen",
		RolesRead: "Lezen",
		RolesUpdate: "Wijzigen",
		ServicesAdd: "Toevoegen",
		ServicesDelete: "Verwijderen",
		ServicesRead: "Lezen",
		ServicesUpdate: "Wijzigen",
		SettingsRead: "Lezen",
		SettingsUpdate: "Wijzigen",
		UsersAdd: "Toevoegen",
		UsersDelete: "Verwijderen",
		UsersRead: "Lezen",
		UsersUpdate: "Wijzigen"
	},
	phase: "Fase",
	phone: "Telefoon",
	photos: "Foto's",
	Planned: "Gepland",
	planning: "Planning",
	poNumber: "PO Nummer",
	postalCode: "Postcode",
	previous: "Vorige",
	prevMonth: "Vorige maand",
	prevWeek: "Vorige week",
	Present: "Aanwezig",
	price: "Prijs €",
	priceCalculation: "Prijsberekening",
	priority: "Prioriteit",
	Priority1: "Prioriteit 1 (4 uur)",
	Priority2: "Prioriteit 2 (8 uur)",
	Priority3: "Prioriteit 3 (24 uur)",
	Priority4: "Prioriteit 4 (48 uur)",
	Priority5: "Prioriteit 5 (5 dagen)",
	Priority6: "Prioriteit 6 (10 dagen)",
	Priority7: "Prioriteit 7 (30 dagen)",
	Priority8: "Prioriteit 8 (60 dagen)",
	privacy: "Privacy",
	projectCode: "Projectcode",
	projectManager: "Projectmanager",
	province: "Provincie",
	purchasePrice: "Aankoopprijs €",
	Q8ErrorMessages: "Dit zijn de error messages die de Q8 API terugstuurd: ",
	QuotationApproved: "Offerte aanvaard",
	QuotationDeclined: "Offerte geweigerd",
	Quoted: "Offerte aangevraagd",
	QuoteRequired: "Offerte vereist",
	rate: "Tarief €",
	rateType: "Tarieftype",
	rateTypes: "Tarieftypes",
	receivedDate: "Ontvangst Datum",
	Red: "Rood",
	Rejected: "Afgekeurd",
	Revisit: "Herbekijken",
	remark: "Opmerking",
	remarks: "Opmerkingen",
	reminderBody: "Reminder",
	reminderDate: "Reminder verzenden op",
	ReminderError: "Reminder error",
	remove: "Verwijderen",
	requests: "Aanvragen",
	requestWorkOrder: "Vraag werkbon aan",
	required: "Vereist",
	reset: "Terugzetten",
	role: "Rol",
	roles: "Rollen",
	salesPrice: "Verkoopprijs €",
	saturday: "Zaterdag",
	sat_tr: "Zat-tr",
	sat_w: "Zat-w",
	save: "Opslaan",
	schedule: "Inplannen",
	scheduledOn: "Gepland op",
	secondPhone: "Tweede telefoon",
	security: "Beveiliging",
	securityDocument: "Veiligheidsdocument",
	securityDocuments: "Veiligheidsdocumenten",
	select_at_least_one: "Gelieve minstens 1 {{entity}} te selecteren.",
	selectAll: "Alles Selecteren",
	selectFilter: "filter selecteren",
	send: "Verzenden",
	Sending: "Verzenden",
	SendingReminder: "Verzenden reminder",
	sendPlannedEmail: "Verzend planningsnotificatie",
	Sent: "Verzonden",
	september: "september",
	service: "Dienst",
	services: "Diensten",
	settings: "Instellingen",
	showInMobileApp: "Toon in mobiele app",
	showMaterialPrices: "Toon materiaal prijzen",
	showMaterialPricesPdf: "Toon materiaal prijzen in workorder.pdf",
	showToBeSignedOff: "Toon af te melden opdrachten",
	SignedOff: "Afgemeld",
	signedOff: "Afgemeld",
	signedOffBy: "Afgemeld door",
	signedOffDate: "Datum afmelding",
	signOff: "Opdracht afmelden",
	SolvedByPhone: "Opgelost per telefoon",
	status: "Status",
	Stopped: "Gestopt",
	street: "Straat",
	subject: "Onderwerp",
	success: "Success",
	sunday: "Zondag",
	sun_tr: "Zo-tr",
	sun_w: "Zo-w",
	technician: "Technieker",
	template: "Template",
	theseAreTheServerErrorMessages: "Dit zijn de server validatie errors:",
	timeline: "Tijdslijn",
	thursday: "Donderdag",
	thu_tr: "Do-tr",
	thu_w: "Do-w",
	timepicker: {
		cancel: "Annuleer",
		now: "NU",
		selectNow: "Selecteer Nu",
		set: "Kies",
		toggleClock: "Wissel klok",
		toggleTimeSelector: "Open/sluit"
	},
	timeMaterial: "Regie",
	title: "Titel",
	to: "Aan",
	ToBeOrdered: "Te bestellen",
	ToBePrepared: "Klaar te leggen",
	ToBeSent: "Te verzenden",
	total: "Totaal",
	tot_tr: "Tot-tr",
	tot_w: "Tot-w",
	totalMaterialPurchasePrice: "Totaal materiaal aankoopprijs",
	totalMaterialSalesPrice: "Totaal materiaal verkoopprijs",
	totalPrice: "Totaal prijs",
	totalPurchasePrice: "Totaal aankoopprijs",
	totalSalesPrice: "Totaal verkoppprijs",
	transportDuration: "Transport tijd",
	tuesday: "Dinsdag",
	tue_tr: "Di-tr",
	tue_w: "Di-w",
	type: "Type",
	Update: "Gewijzigd",
	updatedDateByUser: "Laatst bewerkt op",
	upload: {
		cancel: "Annuleer",
		clearSelectedFiles: "Maak leeg",
		dropFilesHere: "Bestanden hier neerzetten om te uploaden",
		files: "bestanden",
		headerStatusUploaded: "Klaar",
		headerStatusUploading: "Uploaden...",
		invalidFileExtension: "Bestandstype is niet toegestaan.",
		invalidFiles: "Invalid file(s). Please check file upload requirements.",
		invalidMaxFileSize: "Bestand is te groot.",
		invalidMinFileSize: "Bestand is te klein.",
		remove: "Verwijder",
		retry: "Probeer opnieuw",
		select: "Selecteer bestanden...",
		total: "Totaal",
		uploadSelectedFiles: "Upload"
	},
	urgent: "Dringend",
	urgentIntervention: "Dringende interventie",
	urgentInterventionAmount: "Bedrag dringende interventie",
	user: "Gebruiker",
	users: "Gebruikers",
	VatBusiness21: "Bedrijven - 21%",
	VatEqualizationCustomers21: "Egalisatie klanten - 21%",
	VatExemption0: "Vrijgesteld - 0%",
	VatIndividuals21: "Particulieren, scholen, ... - 21%",
	vatNumber: "BTW Nummer",
	vatRegime: "BTW Regime",
	vehicleType: "Type Voertuig",
	version: "Versie",
	view: "Open",
	warehouse: "Magazijn",
	wednesday: "Woensdag",
	wed_tr: "Woe-tr",
	wed_w: "Woe-w",
	welcome_message: "Welkom ",
	woNumber: "WO Nummer",
	work: "Werk",
	WorkComplete: "Werk compleet",
	workOrders: "Werkbonnen",
	workorderSuccesfullyRequested: "Werkbon succesvol aangevraagd",
	workPermit: "Werkvergunning",
	year: "jaar",
	yes: "Ja",
	yourprofile: "Jouw profiel"
};

export default translationsNL;
