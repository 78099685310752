import React, { Suspense, useEffect } from "react";

import { AuthenticationResult } from "@azure/msal-browser";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { Loader } from "@selas/ui-components";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import Login from "./components/login";
import LoginError from "./components/login/loginError";
import { IPortalProps } from "./components/portal";
import Endpoint from "./services/api/endpoint";
import { logIn } from "./services/authentication";
import { IAuthenticationState } from "./state";
import { checkLoggedInUser, setLoginError, userLoggedIn } from "./state/actions/authActions";
import { IApplicationState } from "./store";

import "bootstrap-4-grid/css/grid.min.css";
import "./assets/main.scss";

const Portal: React.LazyExoticComponent<React.FC<IPortalProps>> = React.lazy(() => import("./components/portal"));

interface IProps {
	loginLogo: string;
	portalLogo: string;
}

const App: React.FC<IProps> = (props: IProps) => {
	const apiService: ApiCommunicator = useApiService();
	const authenticationState: IAuthenticationState = useSelector((state: IApplicationState) => state.authenticationState);
	const dispatch: ThunkDispatch<IApplicationState, {}, AnyAction> = useDispatch<ThunkDispatch<IApplicationState, {}, AnyAction>>();

	useEffect(() => {
		dispatch(checkLoggedInUser());
	}, [dispatch]);

	useEffect(() => {
		if (authenticationState.authenticated) {
			dispatch(apiService.callApiRedux(Endpoint.CurrentUser, "GET"));
		}
	}, [dispatch, authenticationState.authenticated, apiService]);

	async function login(): Promise<void> {
		try {
			const response: AuthenticationResult = await logIn();
			dispatch(userLoggedIn(response.account));
		} catch (err) {
			dispatch(setLoginError(err.errorCode, err.errorMessage));
		}
	}

	if (authenticationState.authenticated && authenticationState.currentUser) {
		return (
			<Suspense fallback={<Loader />}>
				<Portal logo={props.portalLogo} />
			</Suspense>
		);
	} else if (authenticationState.authenticated && authenticationState.loadingUser) {
		return <Loader />;
	} else if (authenticationState.loginError) {
		return <LoginError />;
	} else {
		return <Login logo={props.loginLogo} login={login} />;
	}
};

export default App;
