import { IAction } from "@selas/models";
import { createEntityReducer } from "@selas/state-management";
import { AxiosError, AxiosResponse } from "axios";

import { ICustomerMaterialState, initialCustomerMaterialState } from "..";
import Endpoint from "../../services/api/endpoint";
import { ICustomerMaterial } from "../../utils/types/models";

function customerMaterialReducer(state: ICustomerMaterialState, action: IAction): ICustomerMaterialState {
	switch (action.type) {
		case "REQUEST_POST_" + Endpoint.CustomerMaterialsCopyFromCategory:
			state = {
				...state,
				isCopying: true,
				copyingError: null,
				copyingComplete: false
			};
			break;
		case "REQUEST_SUCCESS_POST_" + Endpoint.CustomerMaterialsCopyFromCategory:
			state = {
				...state,
				copyingComplete: true
			};
			break;
		case "REQUEST_FAILURE_POST_" + Endpoint.CustomerMaterialsCopyFromCategory: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				copyingError: payload.error
			};
			break;
		}
		case "REQUEST_END_POST_" + Endpoint.CustomerMaterialsCopyFromCategory:
			state = {
				...state,
				isCopying: false
			};
			break;
		case "CLEAR_POST_" + Endpoint.CustomerMaterialsCopyFromCategory:
			state = {
				...state,
				isCopying: null,
				copyingError: null
			};
			break;
		case "REQUEST_GET_" + Endpoint.CustomerMaterialsByCategory:
			state = {
				...state,
				isListLoading: true,
				entitiesLoadError: null
			};
			break;
		case "REQUEST_SUCCESS_GET_" + Endpoint.CustomerMaterialsByCategory:
			const payloadSucces: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (action.payload && payloadSucces.response && payloadSucces.response.data) {
				state = {
					...state,
					entities: payloadSucces.response.data
				};
			}
			break;
		case "REQUEST_FAILURE_GET_" + Endpoint.CustomerMaterialsByCategory: {
			const payloadCategory: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				entitiesLoadError: payloadCategory.error
			};
			break;
		}
		case "REQUEST_END_GET_" + Endpoint.CustomerMaterialsByCategory:
			state = {
				...state,
				isListLoading: false
			};
			break;
		case "CLEAR_GET_" + Endpoint.CustomerMaterialsByCategory:
			state = {
				...state,
				entities: null,
				isListLoading: false,
				entitiesLoadError: null
			};
			break;
	}
	return state;
}

export default createEntityReducer<ICustomerMaterial, ICustomerMaterialState>(Endpoint.CustomerMaterials, undefined, customerMaterialReducer, initialCustomerMaterialState);
