import React from "react";

import { StandardButton } from "@selas/ui-components";

import styles from "./login.module.scss";

interface IProps {
	logo: string;
	login: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Login: React.FC<IProps> = (props: IProps) => {
	return (
		<div className={styles.container + " d-flex align-items-center justify-content-center"}>
			<div className={styles.logIn + " d-flex flex-column align-items-center justify-content-center"}>
				<img src={`${process.env.PUBLIC_URL}/${props.logo}`} className={styles.logo} alt="logo" />
				<div id={styles.login}>LOGIN</div>
				<p>Welkom terug! Log in door op de knop te klikken</p>
				<StandardButton imageUrl="microsoft.png" primary onClick={props.login}>
					Log in met Microsoft 365
				</StandardButton>
			</div>
		</div>
	);
};

export default Login;
