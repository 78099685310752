import { Reducer } from "react";

import { AccountInfo } from "@azure/msal-browser";
import { IAction } from "@selas/models";
import { AxiosError, AxiosResponse } from "axios";

import { IAuthenticationState } from "..";

const initialState: IAuthenticationState = {
	authenticated: false,
	loginError: null,
	account: null,

	loadingUser: false,
	currentUser: null,
	savingCurrentUser: false,
	savingCurrentUserError: null
};

// Reducer that has to do with the logged in user.
const authenticationReducer: Reducer<IAuthenticationState, IAction> = (state: IAuthenticationState = initialState, action: IAction) => {
	switch (action.type) {
		case "SET_LOGIN_SUCCESS":
			state = {
				...state,
				authenticated: true,
				account: action.account as AccountInfo,
				loginError: null
			};
			break;
		case "SET_LOGIN_ERROR":
			state = {
				...state,
				authenticated: false,
				loginError: action.errorDesc + ": " + action.error,
				account: action.account as AccountInfo
			};
			break;
		case "SET_LOGOUT_SUCCESS":
			state = {
				...state,
				authenticated: false,
				account: null,
				currentUser: null,
				loginError: null
			};
			break;
		case "SET_CURRENTLANGUAGE":
			if (state.currentUser) {
				state = {
					...state,
					currentUser: {
						...state.currentUser,
						language: action.language as string
					}
				};
			}
			break;
		case "REQUEST_GET_CURRENT_USER":
			state = {
				...state,
				currentUser: null,
				loadingUser: true
			};
			break;
		case "REQUEST_SUCCESS_GET_CURRENT_USER": {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			state = {
				...state,
				currentUser: payload.response.data,
				loginError: null
			};
			break;
		}
		case "REQUEST_FAILURE_GET_CURRENT_USER": {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				loginError: payload.error
			};
			break;
		}
		case "REQUEST_END_GET_CURRENT_USER":
			state = {
				...state,
				loadingUser: false
			};
			break;

		case "REQUEST_PUT_CURRENT_USER":
			state = {
				...state,
				savingCurrentUser: true,
				savingCurrentUserError: null
			};
			break;
		case "REQUEST_FAILURE_PUT_CURRENT_USER": {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				savingCurrentUserError: payload.error
			};
			break;
		}
		case "REQUEST_END_PUT_CURRENT_USER":
			state = {
				...state,
				savingCurrentUser: false
			};
			break;
		default:
			return state;
	}
	return state;
};

export default authenticationReducer;
