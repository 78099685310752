import React from "react";

import { StandardButton } from "@selas/ui-components";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { authContext } from "../../services/authentication";
import { IAuthenticationState } from "../../state";
import { userLoggedOut } from "../../state/actions/authActions";
import { IApplicationState } from "../../store";

const LoginError: React.FC = () => {
	const authenticationState: IAuthenticationState = useSelector((state: IApplicationState) => state.authenticationState);
	const dispatch: Dispatch = useDispatch();

	function close(): void {
		dispatch(userLoggedOut());
	}

	function logout(): void {
		try {
			authContext().logout();
			dispatch(userLoggedOut());
		} catch {
			// Ignore
		}
	}

	let dialogContent: JSX.Element = <div>{`The following error occured login in: ${authenticationState.loginError.toString()}.`}</div>;
	if (authenticationState.loginError.response && authenticationState.loginError.response.status === 403) {
		dialogContent = (
			<div>
				You don't have an account on this application.
				<br />
				Please contact <a href="mailto:support@selas.be">support@selas.be</a>.
			</div>
		);
	}

	return (
		<Dialog title="Login Error" onClose={close}>
			<div style={{ margin: "25px", textAlign: "center" }}>
				Dear {authenticationState.account?.name}, <br />
				<br />
				{dialogContent}.
			</div>
			<DialogActionsBar>
				<StandardButton className="k-button" onClick={close}>
					Close
				</StandardButton>
				<StandardButton className="k-button" onClick={logout} primary>
					Logout
				</StandardButton>
			</DialogActionsBar>
		</Dialog>
	);
};

export default LoginError;
