import { IEndpointType } from "@selas/api-communication";
import { IDictionary } from "@selas/models";

import Endpoint from "./endpoint";

let endpoints: IDictionary<IEndpointType>;
endpoints = {};
endpoints[Endpoint.Roles] = {
	href: "/api/roles/:id",
	authNeeded: true
};
endpoints[Endpoint.RolesList] = {
	href: "/api/roles/list",
	authNeeded: true
};
endpoints[Endpoint.Users] = {
	href: "/api/users/:id",
	authNeeded: true
};
endpoints[Endpoint.UsersList] = {
	href: "/api/users/list",
	authNeeded: true
};
endpoints[Endpoint.CurrentUser] = {
	href: "/api/users/currentuser",
	authNeeded: true
};
endpoints[Endpoint.Permissions] = {
	href: "/api/permissions",
	authNeeded: true
};
endpoints[Endpoint.Settings] = {
	href: "/api/settings",
	authNeeded: true
};
endpoints[Endpoint.MaterialCategoriesList] = {
	href: "/api/materialcategories/list",
	authNeeded: true
};
endpoints[Endpoint.MaterialCategories] = {
	href: "/api/materialcategories/:id",
	authNeeded: true
};
endpoints[Endpoint.ContactTypesList] = {
	href: "/api/contacttypes/list",
	authNeeded: true
};
endpoints[Endpoint.ContactTypes] = {
	href: "/api/contacttypes/:id",
	authNeeded: true
};
endpoints[Endpoint.AssignmentTypesList] = {
	href: "/api/assignmenttypes/list",
	authNeeded: true
};
endpoints[Endpoint.AssignmentTypes] = {
	href: "/api/assignmenttypes/:id",
	authNeeded: true
};
endpoints[Endpoint.RateTypes] = {
	href: "/api/ratetypes/:id",
	authNeeded: true
};
endpoints[Endpoint.RateTypesList] = {
	href: "/api/ratetypes/list",
	authNeeded: true
};
endpoints[Endpoint.CommunicationTemplates] = {
	href: "/api/communicationtemplates/:id",
	authNeeded: true
};
endpoints[Endpoint.CommunicationTemplatesList] = {
	href: "/api/communicationtemplates/list",
	authNeeded: true
};
endpoints[Endpoint.Materials] = {
	href: "/api/materials/:id",
	authNeeded: true
};
endpoints[Endpoint.MaterialsList] = {
	href: "/api/materials/list",
	authNeeded: true
};
endpoints[Endpoint.Services] = {
	href: "/api/services/:id",
	authNeeded: true
};
endpoints[Endpoint.ServicesList] = {
	href: "/api/services/list",
	authNeeded: true
};
endpoints[Endpoint.Customers] = {
	href: "/api/customers/:id",
	authNeeded: true
};
endpoints[Endpoint.CustomersList] = {
	href: "/api/customers/list",
	authNeeded: true
};
endpoints[Endpoint.CustomerMaterials] = {
	href: "/api/customers/:customerId/materials/:id",
	authNeeded: true
};
endpoints[Endpoint.CustomerMaterialsList] = {
	href: "/api/customers/:customerId/materials/list",
	authNeeded: true
};
endpoints[Endpoint.CustomerMaterialsCopyFromCategory] = {
	href: "/api/customers/:customerId/materials/copyfromcategory",
	authNeeded: true
};
endpoints[Endpoint.CustomerServices] = {
	href: "/api/customers/:customerId/services/:id",
	authNeeded: true
};
endpoints[Endpoint.Municipalities] = {
	href: "/api/municipalities",
	authNeeded: true
};
endpoints[Endpoint.Assignments] = {
	href: "/api/assignments/:id",
	authNeeded: true
};
endpoints[Endpoint.AssignmentsList] = {
	href: "/api/assignments/list",
	authNeeded: true
};
endpoints[Endpoint.AssignmentRemarks] = {
	href: "/api/assignments/:assignmentId/remarks/:id",
	authNeeded: true
};
endpoints[Endpoint.AssignementDownloadCosts] = {
	href: "/api/assignments/:id/costfile",
	authNeeded: true
};
endpoints[Endpoint.Countries] = {
	href: "/api/countries",
	authNeeded: true
};
endpoints[Endpoint.Permits] = {
	href: "/api/permits",
	authNeeded: true
};
endpoints[Endpoint.VehicleTypes] = {
	href: "/api/vehicletypes",
	authNeeded: true
};
endpoints[Endpoint.Locations] = {
	href: "/api/customers/:customerId/locations/:id",
	authNeeded: true
};
endpoints[Endpoint.CustomerLocationsList] = {
	href: "/api/customers/:customerId/locations/list",
	authNeeded: true
};
endpoints[Endpoint.Contacts] = {
	href: "/api/customers/:customerId/contacts/:id",
	authNeeded: true
};
endpoints[Endpoint.Interventions] = {
	href: "/api/interventions/:id",
	authNeeded: true
};
endpoints[Endpoint.InterventionsList] = {
	href: "/api/interventions/list",
	authNeeded: true
};
endpoints[Endpoint.InterventionMaterials] = {
	href: "/api/interventionmaterials/:id",
	authNeeded: true
};
endpoints[Endpoint.InterventionMaterialsList] = {
	href: "/api/interventionmaterials/list",
	authNeeded: true
};
endpoints[Endpoint.InterventionRemarks] = {
	href: "/api/interventions/:interventionId/remarks/:id",
	authNeeded: true
};
endpoints[Endpoint.InterventionsSequence] = {
	href: "/api/interventions/changeSequence",
	authNeeded: true
};
endpoints[Endpoint.InterventionRequestWorkOrder] = {
	href: "/api/interventions/:id/requestworkorder",
	authNeeded: true
};
endpoints[Endpoint.Offers] = {
	href: "/api/offers/:id",
	authNeeded: true
};
endpoints[Endpoint.OffersList] = {
	href: "/api/offers/list",
	authNeeded: true
};
endpoints[Endpoint.Technicians] = {
	href: "/api/users/technicians",
	authNeeded: true
};
endpoints[Endpoint.PlannedInterventions] = {
	href: "/api/interventions/planned/:start/:end",
	authNeeded: true
};
endpoints[Endpoint.ToBePlannedInterventions] = {
	href: "/api/interventions/toBePlanned",
	authNeeded: true
};
endpoints[Endpoint.AlreadyPlannedInterventions] = {
	href: "/api/interventions/alreadyPlanned/:interventionId/:plannedDate/:technicianId/:colleagueId",
	authNeeded: true
};
endpoints[Endpoint.Tasks] = {
	href: "/api/tasks",
	authNeeded: true
};
endpoints[Endpoint.AssignmentDescriptionsList] = {
	href: "/api/assignmentdescriptions/list",
	authNeeded: true
};
endpoints[Endpoint.AssignmentDescriptions] = {
	href: "/api/assignmentdescriptions/:id",
	authNeeded: true
};
endpoints[Endpoint.SecurityDocuments] = {
	href: "/api/customers/:customerId/securitydocuments",
	authNeeded: true
};
endpoints[Endpoint.AssignmentsToBeSignedOffList] = {
	href: "/api/assignments/toBeSignedOff",
	authNeeded: true
};
endpoints[Endpoint.AssignmentsToBeSignedOff] = {
	href: "/api/assignments/numberToBeSignedOff",
	authNeeded: true
};
endpoints[Endpoint.EmailRequestsList] = {
	href: "/api/emailrequests/list",
	authNeeded: true
};
endpoints[Endpoint.EmailRequests] = {
	href: "/api/emailrequests",
	authNeeded: true
};
endpoints[Endpoint.CustomerMaterialsByCategory] = {
	href: "/api/customers/:customerId/materialsByCategory",
	authNeeded: true
};
endpoints[Endpoint.InterventionsByUser] = {
	href: "/api/interventions/byUser/:userId",
	authNeeded: true
};
endpoints[Endpoint.WorkOrdersList] = {
	href: "/api/assignments/:assignmentId/workorders/list",
	authNeeded: true
};
endpoints[Endpoint.WorkOrders] = {
	href: "/api/assignments/:assignmentId/workorders/:id",
	authNeeded: true
};
endpoints[Endpoint.AssignmentNumbers] = {
	href: "/api/assignmentnumbers",
	authNeeded: true
};

export default endpoints;
