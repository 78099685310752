enum Endpoint {
	Users = "USERS",
	UsersList = "USERS_LIST",
	CurrentUser = "CURRENT_USER",
	Roles = "ROLES",
	RolesList = "ROLES_LIST",
	Permissions = "PERMISSIONS",
	Settings = "SETTINGS",
	MaterialsList = "MATERIALS_LIST",
	Materials = "MATERIALS",
	MaterialCategoriesList = "MATERIALCATEGORIES_LIST",
	MaterialCategories = "MATERIALCATEGORIES",
	ContactTypes = "CONTACTTYPES",
	ContactTypesList = "CONTACTTYPES_LIST",
	AssignmentTypes = "ASSIGNMENTTYPES",
	AssignmentTypesList = "ASSIGNMENTTYPES_LIST",
	AssignmentDescriptions = "ASSIGNMENTDESCRIPTIONS",
	AssignmentDescriptionsList = "ASSIGNMENTDESCRIPTIONS_LIST",
	RateTypes = "RATETYPES",
	RateTypesList = "RATETYPES_LIST",
	CommunicationTemplates = "COMMUNICATIONTEMPLATES",
	CommunicationTemplatesList = "COMMUNICATIONTEMPLATES_LIST",
	Services = "SERVICES",
	ServicesList = "SERVICES_LIST",
	Customers = "CUSTOMERS",
	CustomersList = "CUSTOMERS_LIST",
	CustomerMaterials = "CUSTOMER_MATERIALS",
	CustomerMaterialsList = "CUSTOMER_MATERIALS_LIST",
	CustomerMaterialsCopyFromCategory = "CUSTOMER_MATERIALS_COPY_FROM_CATEGORY",
	CustomerServices = "CUSTOMER_SERVICES",
	Municipalities = "MUNICIPALITIES",
	Assignments = "ASSIGNMENTS",
	AssignmentsList = "ASSIGNMENTS_LIST",
	AssignmentRemarks = "ASSIGNMENT_REMARKS",
	AssignementDownloadCosts = "ASSIGNMENT_DOWNLOAD_COST",
	Countries = "COUNTRIES",
	Permits = "PERMITS",
	VehicleTypes = "VEHICLETYPES",
	Locations = "LOCATIONS",
	CustomerLocationsList = "CUSTOMER_LOCATIONS_LIST",
	Interventions = "INTERVENTIONS",
	InterventionsList = "INTERVENTIONS_LIST",
	InterventionMaterials = "INTERVENTION_MATERIALS",
	InterventionMaterialsList = "INTERVENTION_MATERIALS_LIST",
	InterventionRemarks = "INTERVENTION_REMARKS",
	InterventionsSequence = "INTERVENTIONS_SEQUENCE",
	InterventionRequestWorkOrder = "INTERVENTINO_REQUEST_WORK_ORDER",
	Offers = "OFFERS",
	OffersList = "OFFERS_LIST",
	Contacts = "CONTACTS",
	Technicians = "TECHNICIANS",
	PlannedInterventions = "PLANNED_INTERVENTIONS",
	ToBePlannedInterventions = "TO_BE_PLANNED_INTERVENTIONS",
	AlreadyPlannedInterventions = "ALREADY_PLANNED_INTERVENTIONS",
	Tasks = "TASKS",
	SecurityDocuments = "SECURITY_DOCUMENTS",
	AssignmentsToBeSignedOffList = "ASSIGNMENTS_TO_BE_SIGNED_OFF_LIST",
	AssignmentsToBeSignedOff = "ASSIGNMENTS_TO_BE_SIGNED_OFF",
	EmailRequestsList = "EMAIL_REQUESTS_LIST",
	EmailRequests = "EMAIL_REQUESTS",
	CustomerMaterialsByCategory = "CUSTOMER_MATERIALS_BY_CATEGORY",
	InterventionsByUser = "INTERVENTIONS_BY_USER",
	WorkOrders = "WORK_ORDERS",
	WorkOrdersList = "WORK_ORDERS_LIST",
	AssignmentNumbers = "ASSIGNMENT_NUMBERS"
}

export default Endpoint;
