import { AuthenticationResult, AuthError, Configuration, PopupRequest, PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { AxiosRequestConfig } from "axios";
import some from "lodash/some";
import { setLoginError } from "../state/actions/authActions";

import store from "../store";
import { Permission } from "../utils/enums";
import { IUser } from "../utils/types/models";

let _tenantId: string;
let _clientId: string;

let _authContext: PublicClientApplication;

export function setAdCredentials(tenantId: string, clientId: string): void {
	_tenantId = tenantId;
	_clientId = clientId;
}

export function authContext(): PublicClientApplication {
	if (_authContext) {
		return _authContext;
	}

	const tenantId: string = _tenantId;
	const clientId: string = _clientId;

	const msalConfiguration: Configuration = {
		auth: {
			clientId,
			authority: `https://login.microsoftonline.com/${tenantId}`,
			redirectUri: window.location.origin
		},
		cache: {
			cacheLocation: "localStorage",
			storeAuthStateInCookie: false
		}
	};

	_authContext = new PublicClientApplication(msalConfiguration);

	return _authContext;
}

export async function logIn(): Promise<AuthenticationResult> {
	return authContext().loginPopup({
		scopes: [`${_clientId}/User.Read`],
		prompt: "select_account"
	});
}

export async function addAuthentication(requestOptions: AxiosRequestConfig): Promise<AxiosRequestConfig> {
	if (!requestOptions.headers) {
		requestOptions.headers = {};
	}

	const localAuthContext: PublicClientApplication = authContext();
	let response: AuthenticationResult;
	const req: SilentRequest = {
		scopes: [`${_clientId}/User.Read`],
		account: localAuthContext.getAllAccounts()[0]
	};
	try {
		response = await localAuthContext.acquireTokenSilent(req);
	} catch (silentError) {
		if (silentError instanceof AuthError || silentError.errorCode === "consent_required" || silentError.errorCode === "interaction_required" || silentError.errorCode === "login_required") {
			const popupRequest: PopupRequest = {
				scopes: req.scopes
			};

			try {
				response = await localAuthContext.acquireTokenPopup(popupRequest);
			} catch (popupError) {
				store.dispatch(setLoginError(popupError.errorCode, popupError.errorMessage));
			}
		}
	}
	if (response) {
		requestOptions.headers.Authorization = `Bearer ${response.accessToken}`;
	}
	requestOptions.headers["Content-Type"] = "application/json";
	// set response type for filedownload
	if (requestOptions.url.endsWith("/costfile")) {
		requestOptions.responseType = "arraybuffer";
	}
	return requestOptions;
}

export function hasPermission(...permissions: Permission[]): boolean {
	const currentUser: IUser = store.getState().authenticationState.currentUser;

	if (!permissions || permissions.length <= 0) {
		return true;
	}

	if (currentUser) {
		return some(permissions, (permision: string) => {
			return currentUser.permissions[permision];
		});
	}

	return false;
}
