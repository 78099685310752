import React from "react";

import { notify } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import { AxiosError } from "axios";
import i18next from "i18next";
import forIn from "lodash/forIn";
import map from "lodash/map";

import store from "../store";
import { IQ8ErrorMessage } from "../utils/types/models";

export default function handleError(error: AxiosError): void {
	const { response } = error;
	if (response) {
		const title: string = i18next.t("error");
		let content: string | JSX.Element = i18next.t("errors.server_error", { statuscode: response.status });
		switch (response.status) {
			case 400:
				if (response.data.errors || response.data.messages) {
					const errors: JSX.Element[] = [];
					if (response.data.messages) {
						forIn(response.data.messages, (value: IQ8ErrorMessage) => {
							errors.push(<li key={newKey("error")}>{value.value}</li>);
						});
						content = (
							<React.Fragment>
								<div>{i18next.t("Q8ErrorMessages")}</div>
								<ul>{errors}</ul>
							</React.Fragment>
						);
					} else {
						forIn(response.data.errors, (value: string[], key: string) => {
							errors.push(
								<li key={key}>
									{i18next.t(key)}:
									<ul>
										{map(value, (validationError: string) => (
											<li key={newKey("error")}>{validationError}</li>
										))}
									</ul>
								</li>
							);
						});
						content = (
							<React.Fragment>
								<div>{i18next.t("theseAreTheServerErrorMessages")}</div>
								<ul>{errors}</ul>
							</React.Fragment>
						);
					}
				} else if (typeof response.data === "string") {
					const errorKey: string = "errors." + response.data;
					if (i18next.exists(errorKey)) {
						content = i18next.t(errorKey);
					}
				} else if (response.data.message === "error_securitydocumencopyfailed") {
					content = i18next.t(response.data.message, { reason: i18next.t("response.data.reason"), file: response.data.file });
				}
				break;
			case 401:
				content = i18next.t("errors.not_authenticated");
				break;
			case 403:
				content = i18next.t("errors.forbidden");
				break;
			case 404:
				content = i18next.t("errors.not_found");
				break;
			case 500:
				content =
					response.data.message === "error_argumentnull"
						? i18next.t("errors." + response.data.message, { argument: i18next.t(response.data.paramName) })
						: (content = i18next.t("errors." + (response.data.message || "error_general")));
				break;
		}

		store.dispatch(notify(title, content, "error"));
	}
}
