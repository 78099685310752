import { IAction } from "@selas/models";
import { createEntityReducer } from "@selas/state-management";
import { AxiosError, AxiosResponse } from "axios";

import { IInterventionState, initialInterventionState } from "..";
import Endpoint from "../../services/api/endpoint";
import { IIntervention } from "../../utils/types/models";

function interventionReducer(state: IInterventionState, action: IAction): IInterventionState {
	switch (action.type) {
		case "REQUEST_GET_" + Endpoint.InterventionRequestWorkOrder:
			state = {
				...state,
				isRequestingWorkOrder: true,
				workOrderRequestError: null,
				workOrderRequestComplete: false
			};
			break;
		case "REQUEST_SUCCESS_GET_" + Endpoint.InterventionRequestWorkOrder:
			state = {
				...state,
				workOrderRequestComplete: true
			};
			break;
		case "REQUEST_FAILURE_GET_" + Endpoint.InterventionRequestWorkOrder: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				workOrderRequestError: payload.error
			};
			break;
		}
		case "REQUEST_END_GET_" + Endpoint.InterventionRequestWorkOrder:
			state = {
				...state,
				isRequestingWorkOrder: false
			};
			break;
		case "CLEAR_GET_" + Endpoint.InterventionRequestWorkOrder:
			state = {
				...state,
				isRequestingWorkOrder: false,
				workOrderRequestError: null
			};
			break;
		case "REQUEST_GET_" + Endpoint.InterventionsByUser:
			state = {
				...state,
				areEntitiesLoading: true,
				entitiesLoadError: null
			};
			break;
		case "REQUEST_SUCCESS_GET_" + Endpoint.InterventionsByUser: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (action.payload && payload.response && payload.response.data) {
				state = {
					...state,
					entities: payload.response.data
				};
			}
			break;
		}
		case "REQUEST_FAILURE_GET_" + Endpoint.InterventionsByUser: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				entitiesLoadError: payload.error
			};
			break;
		}
		case "REQUEST_END_GET_" + Endpoint.InterventionsByUser:
			state = {
				...state,
				areEntitiesLoading: false
			};
			break;
		case "CLEAR_GET_" + Endpoint.InterventionsByUser:
			state = {
				...state,
				entities: initialInterventionState.entities,
				areEntitiesLoading: initialInterventionState.areEntitiesLoading,
				entitiesLoadError: initialInterventionState.entitiesLoadError
			};
			break;
	}
	return state;
}

export default createEntityReducer<IIntervention, IInterventionState>(Endpoint.Interventions, Endpoint.InterventionsList, interventionReducer);
